.App {
  text-align: center;
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro";
}

.error {
  color: red;
}

.success {
  color: green;
}

body {
  overflow: hidden;
}

body.light-mode {
  background-color: #fff;
  color: #333;
  transition: background-color 0.3s ease;
}
body.dark-mode {
  background-color: #1a1919;
  color: #999;
}
