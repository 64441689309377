.rcw-conversation-container > .rcw-header {
    background-color: #787CB2;
  }

.rcw-launcher {
    background-color: #187770;
}
  
.rcw-response > .rcw-message-text {
    background-color: #1D958D;
}

.rcw-snippet {
    background-color: #1D958D;
}

.quick-button {
    border: 2px solid #187770;
}

.rcw-message-text {
    color: #f4f7f9;
}

.rcw-snippet-title {
    color: #f4f7f9;
}

.rcw-client > .rcw-message-text {
    background-color: #2C728A;
}